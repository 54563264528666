import { render, staticRenderFns } from "./exemptionClause.vue?vue&type=template&id=2083591a&scoped=true"
import script from "./exemptionClause.vue?vue&type=script&lang=js"
export * from "./exemptionClause.vue?vue&type=script&lang=js"
import style0 from "./exemptionClause.vue?vue&type=style&index=0&id=2083591a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2083591a",
  null
  
)

export default component.exports