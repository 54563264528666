<template>
  <div class="list">
    <div class="tip">
      <p>温馨提示：请在投保前阅读并确认以下内容</p>
    </div>
    <div class="msg">
      <p>
        本保险产品仅对被保险人在实施接种本合同保障针次的疫苗过程中或者实施接种后出现疑似预防接种异常反应，经卫生健康主管部门认可的机构按法定的调查诊断及鉴定程序认定为由本合同保障针次的疫苗导致预防接种异常反应或不排除预防接种异常反应，本公司按照约定承担保险责任。
      </p>
      <p class="bold">
        因下列一个或者多个情形引起的保险事故，本公司不承担给付保险金的责任：
      </p>
      <p class="bold">1)《中华人民共和国保险法》规定不予支付的事项；</p>
      <p class="bold">
        2)《中华人民共和国疫苗管理法》和接种当地预防接种异常反应补偿政策规定不予支付的事项；
      </p>
      <p class="bold">
        3）对于有接种禁忌而不能接种的被保险人，在医护人员提出医学建议后，被保险人或被保险人的监护人仍要求实施接种；
      </p>
      <p class="bold">
        4）在中国境外接受预防接种或就医的；被保险人在追溯期之前接种的疫苗导致的预防接种异常反应；
      </p>
      <p class="bold">
        5）被保险人在追溯期之前接种的疫苗导致的预防接种异常反应。
      </p>
      <p class="bold">下列情形不属于预防接种异常反应：</p>
      <p class="bold">1）因疫苗本身特性引起的接种后一般反应；</p>
      <p class="bold">2）因疫苗质量问题给受种者造成的损害；</p>
      <p class="bold">
        3）因接种单位违反预防接种工作规范、免疫程序、疫苗使用指导原则、接种方案给受种者造成的损害；
      </p>
      <p class="bold">
        4）受种者在接种时正处于某种疾病的潜伏期或者前驱期，接种后偶合发病；
      </p>
      <p class="bold">
        5）受种者有疫苗说明书规定的接种禁忌，在接种前受种者或者其监护人未如实提供受种者的健康状况和接种禁忌等情况，接种后受种者原有疾病急性复发或者病情加重；
      </p>
      <p class="bold">6）因心理因素发生的个体或者群体的心因性反应。</p>
      <p>
        本公司对发生的、属于保险责任范围内的各项费用，以本合同约定的各项保险金额为限承担给付责任，且累计承担的各项费用之和不得超过本合同约定的累计给付限额。当累计承担的各项费用之和达到本合同约定的累计给付限额时，本公司对被保险人的保险责任终止。
      </p>
    </div>
    <div class="btn" @click="fileList">
      <p>我已阅读并同意以上内容</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    //跳转保险条款
    fileList() {
      this.$router.push({
        path: "/fileList",
        query: {
          channel: "technologicalProcess",
          type: "productTerms",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold !important;
  color: #000 !important;
}
.tip {
  width: 100%;
  height: 44px;
  background: #fff7f4;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 14px;
    font-weight: 400;
    color: $primary-color;
    line-height: 21px;
  }
}
.msg {
  margin-top: 16px;
  padding: 0 20px;
  padding-bottom: 100px;
  p {
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    text-align: justify;
  }
}
.btn {
  width: 100%;
  height: 90px;
  background: #fff;
  position: fixed;
  z-index: 1000;
  left: 0;
  bottom: 0;
  p {
    width: 234px;
    height: 45px;
    margin: 0 auto;
    background: $primary-color;
    border-radius: 23px;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
  }
}
</style>